<template>
  <div class="h-full flex flex-col">
    <Card class="mb-4">
      <template v-slot:content>
        <MapRouteSearchForm
          :payload="params"
          :error="error"
          :loading="loading"
          @showingTruckSpecific="showingTruckSpecific()"
          :isZonesShown="showZones"
          :isTruckSpecific="showTruckSpecific"
        />
      </template>
    </Card>
    <div class="flex-1 flex flex-col" v-if="mapContainer && !loading && markers.length > 0">
      <gmap-tracking
        :trackings="markers"
        :zonesUUIDS="zonesUUIDS"
        :center="center"
        :zoom="zoom"
        :isTruckSpecifShown="showTruckSpecific"
      />
      <GmapLegend />
    </div>
    <div class="flex-1 flex items-center justify-center" v-else-if="error">
      <div class="text-center">
        <h1 class="text-2xl font-bold">{{ error }}</h1>
        <p class="text-gray-500">Please try reducing the time range or contact support.</p>
      </div>
    </div>
    <div class="flex-1 flex items-center justify-center" v-else-if="!loading && markers.length === 0">
      <div class="text-center">
        <h1 class="text-2xl font-bold">No data found</h1>
        <p class="text-gray-500">Please try again later or contact support.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed, onMounted, ref, inject } from "vue";
import trackingTelemetry from "../../../api/tracking-telemetry";
import fetchShadow from "../../../api/shadow/fetch-shadow";
import { convertZonedToUTC } from "../../../utils/dates";
import { Tracking } from "../../../model/trackings";
import { Overspeed } from "../../../model/overspeed";
import { set } from "date-fns";

const route = useRoute();
//NOTIFICATION STORE
const notificationsStore = inject("notificationsStore");
const loadingStore = inject("uiStore");

const params = computed(() => {
  return { ...route.query, uuid: route.params.uuid || null };
});

const error = ref("");
const loading = ref(false);
const markers = ref([]);
const showZones = ref(false);
const showTruckSpecific = ref(false);
const zonesUUIDS = ref([]);
const center = ref({lat: 41.850033, lng: -87.6500523});
const zoom = ref(8);
const mapContainer = ref(null);

const userStore = inject("userStore");
const userUnits = computed(() => {
  if (!userStore.state.user.units.loading) {
    return userStore.state.user.units.data;
  }
  return [];
});

onMounted(() => {
  mapContainer.value = true;
});

const getTelemetry = async (data) => {
  loading.value = true;
  loadingStore.tooglePageLoader(true);
  error.value = "";

  const deviceIdentifier = userUnits.value.filter((u) => u.unit_uuid === data.unit)[0].device_identifier;
  const resp = await fetchShadow({serial: deviceIdentifier, state: 'reported', shadowName: 'configuration'});
  const isOsm = resp?.data?.version?.jarvis_services_versions?.esmart_ai_fusion;

  data.is_osm = isOsm ? true : false;
  
  const response = await trackingTelemetry(data);

  if (!response.data) {
    error.value = response.message;
    notificationsStore.addNotification({
      type: "error",
      title: `Error fetching device telemetry`,
      content: "Please try reducing the time range or contact support.",
    });
  }
  //If there is no data inside the response
  else if (response.count === 0 || response.data.length === 0) {
    notificationsStore.addNotification({
      type: "error",
      title: `Can't find device telemetry`,
      content: "Please try later or contact support.",
    });
  } else {
    const activePositions = response.data;
    // We gather the overspeeds to be able to send any overspeed event time range to the 
    // tracking model to be able to show the overspeeds on the map
    const overspeeds = Overspeed.filterTrackingOverspeeds(activePositions);
    // Now we build our trackings
    const trackings = Tracking.buildTrackings(activePositions, userUnits.value, overspeeds, true, isOsm);
    notificationsStore.addNotification({
      type: "success",
      title: `Telemetry success`,
    });
    zonesUUIDS.value = Tracking.getZonesUUIDS(activePositions);
    zonesUUIDS.value = [...new Set(zonesUUIDS.value)];
    markers.value = trackings;
    center.value = {lat: trackings[0]?.position?.lat, lng: trackings[0]?.position?.long};
    zoom.value = 11;
  }
  loading.value = false;
  loadingStore.tooglePageLoader(false);
};

const showingTruckSpecific = () => {
  showTruckSpecific.value = !showTruckSpecific.value;
};

if (
  params.value.uuid &&
  params.value.date &&
  params.value.start &&
  params.value.end
) {

  const start = params.value.start.split(":");
  const end = params.value.end.split(":");
  const dateGood = new Date(params.value.date).toISOString().split('T')[0]
  const data = {
    unit: params.value.uuid,
    start: convertZonedToUTC(
      set(new Date(), {
        year: dateGood.split("-")[0],
        month: dateGood.split("-")[1] - 1,
        date: dateGood.split("-")[2],
        hours: start[0],
        minutes: start[1],
        seconds: start[2],
      })
    ),
    end: convertZonedToUTC(
      set(new Date(), {
        year: dateGood.split("-")[0],
        month: dateGood.split("-")[1] - 1,
        date: dateGood.split("-")[2],
        hours: end[0],
        minutes: end[1],
        seconds: end[2],
      })
    ),
  };
  getTelemetry(data);
}
</script>