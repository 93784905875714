import { createApp } from 'vue';
import App from "./App.vue";
import "./index.css";
import Router from "./router";

//STORES
import navigationStore from "./store/navigation"
import gmapStore from "./store/gmap"
import deviceStore from "./store/devices"
import userStore from "./store/user"
import notificationsStore from "./store/notifications"
import uiStore from "./store/ui"

//OTHERS
import { Vue3Mq } from "vue3-mq";
import VueGoogleMaps from "vue-google-maps-community-fork";
import { createPinia } from 'pinia';

// Monitoring
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'

import i18n from '../i18n';

if (!['dev', 'testing', 'pre-production'].includes(import.meta.env.VITE_ENVIRONMENT)) {
    datadogRum.init({
        applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'esmart-client-app',
        sampleRate: 100,
        trackInteractions: true,
        version: '1.0.0',
        defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
    datadogLogs.init({
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'esmart-client-app',
        forwardErrorsToLogs: true,
        sampleRate: 100
    })
}

const ClickOutside = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
}

createApp(App)
    .use(Router)
    .use(createPinia())
    .use(Vue3Mq, {
        breakpoints: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280
        }
    })
    .use(i18n)
    .use(VueGoogleMaps, {
        load: {
            key: import.meta.env.VITE_GMAP_CREDENTIAL,
            libraries: 'visualization',
        }
    })
    .provide("navigationStore", navigationStore)
    .provide("gmapStore", gmapStore)
    .provide("deviceStore", deviceStore)
    .provide("userStore", userStore)
    .provide("notificationsStore", notificationsStore)
    .provide("uiStore", uiStore)
    .mount("#app");
