<template>
  <GMapMap :center="center" :zoom="zoom" :style="mapStyle" :options="options" :max-zoom="17" :min-zoom="5" >
    <GMapMarker
      :key="index"
      v-if="props.locations.length > 0"
      v-for="(location, index) in props.locations"
      :position="{lat: location.position.lat, lng: location.position.long}"
      :clickable="true"
      :draggable="false"
      @click="openInfoWindow(location)"
      :icon="{
        url: properSVG(location.type),
        scaledSize: {height: 27, width: 20}
      }"
    />
    <GMapInfoWindow
      v-if="activeLocation"
      :position="{lat: activeLocation.position.lat, lng: activeLocation.position.long}"
    >
      <popin-location :data="activeLocation" @close="closePopin()" />
    </GMapInfoWindow>
  </GMapMap>
</template>

<script setup>
import { ref } from "vue";
import { properSVG } from "../../../utils/svg";
import { options } from "../../../constants/map.control";
  
const props = defineProps( {
  locations: {
    type: Array,
    default: [],
  },
  center: Object,
  zoom: Number
});

const activeLocation = ref(null);

const mapStyle = ref({
  height: '83vh',
  width: '100%',
});

const closePopin = () => {
  activeLocation.value = null;
};

const openInfoWindow = (location) => {
  activeLocation.value = location;
};

</script>