const options = {
  clickableIcons: false,
  disableDoubleClickZoom: true,
  streetViewControl: true,
  zoomControl: true,
  mapTypeControl: true,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.attraction',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.government',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.medical',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.park',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.place_of_worship',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.sports_complex',
      stylers: [{ visibility: 'off' }]
    }
  ]
}

export {options};