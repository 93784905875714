import { get } from "../axios.js"

const fetchShadow = async (data) => {

    //If there is all required datas
    if (data && data.serial && data.state) {

        const serial = data.serial;
        const state = data.state;
        let tokens = JSON.parse(localStorage.getItem('esmart_tokens'));
        const id_token = tokens.id_token;
        const pool_id = import.meta.env.VITE_MFA_POOL_ID;

        const headers = {
            "x-aws-id": `Bearer ${id_token}`
        }

        let url = `${import.meta.env.VITE_API_BASE_URL}shadows//lives/${serial}/states/${state}?pool_id=${pool_id}`

        if (data.shadowName) {
            url += `&shadow_name=${data.shadowName}`
        }


        try {
            const response = await get({ headers, data: null, url })
            if (response.data) {
                return response.data
            } else {
                throw new Error("There is no data available.")
            }
        } catch (err) {
            throw err
        }

    }
    //If there is not all the required datas
    else {
        throw new Error("A required data is missing.")
    }
}

export default fetchShadow;